import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  GameState,
  IMove,
  IPiece,
  Mode,
  Position,
} from "../../services/zodiacEngine/types";
import ZodiacEngineService from "../ZodiacEngineService";
import { AuthContext } from "./AuthContext";

interface SelectedPiece {
  piece: IPiece;
  position: Position;
}

interface GameContextType {
  gameState: GameState;
  updateGameState: (newState: GameState) => void;

  selectedPiece?: SelectedPiece;
  updateSelectedPiece: (selectedPiece: SelectedPiece | undefined) => void;

  potentialMoves: IMove[];
  updatePotentialMoves: (moves: IMove[]) => void;

  isThisPlayersTurn: () => boolean;
  isLightPlayer: () => boolean;
}

export const GameContext = createContext<GameContextType | undefined>(
  undefined
);

interface GameContextProviderProps {
  children: ReactNode;
}

export const GameContextProvider: React.FC<GameContextProviderProps> = ({
  children,
}) => {
  const { user } = useContext(AuthContext);

  const [gameState, setGameState] = useState(
    ZodiacEngineService.zodiacEngine.getGameState()
  );
  const [potentialMoves, setPotentialMoves] = useState([] as IMove[]);

  const [selectedPiece, setSelectedPiece] = useState(
    undefined as SelectedPiece | undefined
  );

  useEffect(() => {
    const updateUiState = () => {
      setGameState(ZodiacEngineService.zodiacEngine.getGameState());
    };
    ZodiacEngineService.zodiacEngine.subscribeToGameStateChanges(updateUiState);

    // Cleanup subscription on unmount
    return () => {
      ZodiacEngineService.zodiacEngine.unsubscribeToGameStateChanges(
        updateUiState
      );
    };
  }, []);

  const updateGameState = (gameState: GameState) => {
    setGameState(gameState);
  };

  const updatePotentialMoves = (moves: IMove[]) => {
    setPotentialMoves(moves);
  };

  const updateSelectedPiece = (selectedPiece: SelectedPiece | undefined) => {
    setSelectedPiece(selectedPiece);
  };

  const isThisPlayersTurn = (): boolean => {
    if (gameState.mode === Mode.Dev) return true;
    return (
      user!.uid ===
      (gameState.isLightTurn ? gameState.lightPlayer : gameState.darkPlayer)
    );
  };

  const isLightPlayer = (): boolean => {
    if (gameState.mode === Mode.Dev) return true;
    return user!.uid === gameState.lightPlayer;
  };

  return (
    <GameContext.Provider
      value={{
        gameState,
        potentialMoves,
        selectedPiece,
        updateGameState,
        updatePotentialMoves,
        updateSelectedPiece,
        isThisPlayersTurn,
        isLightPlayer,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
