import {
  getGameStateByGameId,
  listenForDocumentChanges,
  updateGamesDocument,
} from "../../firebase/firebase";
import {
  convertFromFirebaseState,
  convertToFirebaseState,
} from "../../firebase/firebaseConverter";
import { GameStateListener, Mode, State, PieceType } from "../types";
import { Piece } from "../Piece";
import { GameStateManager } from "./GameStateManager";

export class EventManager {
  private gameId?: string;
  private gameStateListeners: Set<GameStateListener> = new Set();
  private unsubscribeDocument: (() => void) | null = null;
  private gameStateManager: GameStateManager;

  constructor(gameStateManager: GameStateManager) {
    this.gameStateManager = gameStateManager;
    this.gameId = undefined;
  }

  getGameId(): string | undefined {
    return this.gameId;
  }

  // Listener management
  subscribeToGameStateChanges(listener: GameStateListener): void {
    this.gameStateListeners.add(listener);
  }

  unsubscribeToGameStateChanges(listener: GameStateListener): void {
    this.gameStateListeners.delete(listener);
  }

  // Notify all listeners of game state changes
  notifyLocalListeners(): void {
    this.gameStateListeners.forEach((listener) => listener());
  }

  setupDevMode() {
    if (this.unsubscribeDocument) {
      this.unsubscribeDocument();
      this.unsubscribeDocument = null;
    }
    this.gameStateManager.emptyBoard();
    this.gameStateManager.setMode(Mode.Dev);
    this.gameStateManager.setGameStateState(State.PreGame);
    this.gameStateManager.resetTurnCount();
    this.gameId = undefined;
    this.notifyLocalListeners();
  }

  // Setup live mode and handle remote communication
  async setupLiveMode(playerId: string): Promise<string> {
    this.gameStateManager.emptyBoard();
    this.gameStateManager.resetTurnCount();
    this.gameStateManager.setMode(Mode.Live);
    this.gameStateManager.setGameStateState(State.Searching);
    this.gameStateManager.setLightPlayer(playerId);

    // Optional: Place initial pieces for testing
    this.setTestState();

    // Create a new game
    this.gameId = crypto.randomUUID();
    this.listenToDocumentChanges();
    this.sendUpdateToRemote();
    return this.gameId;
  }

  async joinGame(gameId: string, playerId: string) {
    const gameStateData = await getGameStateByGameId(gameId);

    if (!gameStateData) throw new Error("you can't be both players fool!");

    var gameState = convertFromFirebaseState(gameStateData);

    if (gameState.lightPlayer! === playerId)
      throw new Error("you can't be both players fool!");

    // We're all good
    this.gameId = gameId;
    this.gameStateManager.setGameState(gameState);
    this.gameStateManager.setDarkPlayer(playerId);
    this.gameStateManager.setGameStateState(State.PreGame);
    this.listenToDocumentChanges();
    this.sendUpdateToRemote();
  }

  listenToDocumentChanges() {
    this.unsubscribeDocument = listenForDocumentChanges(
      this.gameId!,
      (data: any | null) => {
        if (data) {
          console.log("Document changed, new data:", data);
          this.gameStateManager.setGameState(convertFromFirebaseState(data));
          this.notifyLocalListeners();
        } else {
          console.log("Document does not exist");
        }
      }
    );
  }

  sendUpdateToRemote() {
    if (this.gameStateManager.getGameState().mode !== Mode.Live) return;
    if (!this.gameId) throw Error("Game id not set");

    const firebaseState = convertToFirebaseState(
      this.gameStateManager.getGameState()
    );
    updateGamesDocument(this.gameId!, firebaseState)
      .then(() => {
        console.log("Document updated successfully");
      })
      .catch((error: any) => {
        console.error("Error updating document:", error);
      });
  }

  ungracefulTermination() {
    if (this.gameId) {
      this.gameStateManager.setGameStateState(State.Terminated);
      console.log(this.gameId);
      console.log("Ungraceful termination");
    }
  }

  setTestState() {
    this.gameStateManager.placePiece(new Piece(PieceType.Crane, true), {
      col: 0,
      row: 7,
    });
    this.gameStateManager.placePiece(new Piece(PieceType.Tree, true), {
      col: 1,
      row: 7,
    });
    this.gameStateManager.placePiece(new Piece(PieceType.Bear, true), {
      col: 2,
      row: 7,
    });
    this.gameStateManager.placePiece(new Piece(PieceType.Rhino, true), {
      col: 3,
      row: 7,
    });
    this.gameStateManager.placePiece(new Piece(PieceType.Goat, true), {
      col: 4,
      row: 7,
    });

    this.gameStateManager.placePiece(new Piece(PieceType.Crane, false), {
      col: 0,
      row: 0,
    });
    this.gameStateManager.placePiece(new Piece(PieceType.Tree, false), {
      col: 1,
      row: 0,
    });
    this.gameStateManager.placePiece(new Piece(PieceType.Bear, false), {
      col: 2,
      row: 0,
    });
    this.gameStateManager.placePiece(new Piece(PieceType.Rhino, false), {
      col: 3,
      row: 0,
    });
    this.gameStateManager.placePiece(new Piece(PieceType.Goat, false), {
      col: 4,
      row: 0,
    });
  }
}
