import React, { createContext, useEffect, useState, ReactNode } from "react";
import { onAuthStateChanged, signInAnonymously, User } from "firebase/auth";
import { auth } from "../../services/firebase/firebaseConfig";

interface AuthContextValue {
  user: User | null;
}

export const AuthContext = createContext<AuthContextValue>({
  user: null,
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        // No user is signed in, sign in anonymously
        await signInAnonymously(auth);
      } else {
        setUser(currentUser);
      }
      setInitializing(false);
    });

    return () => unsubscribe();
  }, []);

  // While the initial sign-in process or user fetch is happening
  if (initializing) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
