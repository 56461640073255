import globalStyles from "./App.module.css";
import BoardContainer from "./components/BoardContainer";
import { AuthProvider } from "./components/Providers/AuthContext";
import { GameContextProvider } from "./components/Providers/GameContext";

function App() {
  return (
    <div className={globalStyles.app}>
      <AuthProvider>
        <GameContextProvider>
          <BoardContainer />
        </GameContextProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
