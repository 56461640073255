import { GameStateManager } from "./Managers/GameStateManager";
import { MovementManager } from "./Managers/MovementManager";
import { EventManager } from "./Managers/EventManager";
import { GameState, State, PieceType, MoveType, SideEffect } from "./types";
import { Piece } from "./Piece";
import { IMove, IPiece, Position } from "./types";

export type GameStateListener = () => void;

export class ZodiacEngine {
  private eventManager: EventManager;
  private gameStateManager: GameStateManager;
  private movementManager: MovementManager;

  constructor() {
    this.gameStateManager = new GameStateManager();
    this.movementManager = new MovementManager(this.gameStateManager);
    this.eventManager = new EventManager(this.gameStateManager);
  }

  // Game state and game modes
  getGameState(): GameState {
    return { ...this.gameStateManager.getGameState() };
  }

  nextGameState() {
    this.gameStateManager.nextGameState();
  }

  async switchToLiveMove(playerId: string): Promise<string> {
    return await this.eventManager.setupLiveMode(playerId);
  }

  switchToDevMove() {
    this.eventManager.setupDevMode();
  }

  // Event Handling
  subscribeToGameStateChanges(listener: GameStateListener): void {
    this.eventManager.subscribeToGameStateChanges(listener);
  }

  unsubscribeToGameStateChanges(listener: GameStateListener): void {
    this.eventManager.unsubscribeToGameStateChanges(listener);
  }

  ungracefulTermination() {
    if (this.eventManager.getGameId()) {
      this.gameStateManager.getGameState().state = State.Terminated;
      console.log(this.eventManager.getGameId());
      console.log("Ungraceful termination");
    }
  }

  getGameId(): string | undefined {
    return this.eventManager.getGameId();
  }

  joinGame(gameId: string, playerId: string) {
    this.eventManager.joinGame(gameId, playerId);
  }

  // Piece getting
  placePiece(piece: Piece, position: Position) {
    this.gameStateManager.placePiece(piece, position);
    this.eventManager.notifyLocalListeners();
  }

  getPiecesAt(treePosition: { row: number; col: number }): IPiece[] {
    return this.gameStateManager.getPiecesAt(treePosition);
  }

  // Game Setup
  setLightKing(pieceType: PieceType) {
    if (
      this.gameStateManager.getGameState().state !== State.PreGame ||
      this.gameStateManager.getGameState().lightKing
    )
      throw Error("The game state is incorrect to set light king");
    this.gameStateManager.getGameState().lightKing = pieceType;
    this.eventManager.notifyLocalListeners();
  }

  setDarkKing(pieceType: PieceType) {
    if (
      this.gameStateManager.getGameState().state !== State.PreGame ||
      this.gameStateManager.getGameState().darkKing
    )
      throw Error("The game state is incorrect to set dark king");
    this.gameStateManager.getGameState().darkKing = pieceType;
    this.eventManager.notifyLocalListeners();
  }

  setStartingPiece(
    pieceType: PieceType,
    isLight: boolean,
    col: 0 | 1 | 2 | 3 | 4
  ) {
    if (this.gameStateManager.getGameState().state !== State.PreGame)
      throw Error("The game state is incorrect to set a starting piece");
    this.gameStateManager.setStartingPiece(pieceType, isLight, col);
    this.eventManager.notifyLocalListeners();
  }

  // Movement Handling
  getValidMoves(position: Position, piece: IPiece): IMove[] {
    return this.movementManager.getValidMoves(position, piece);
  }

  makeMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece | null,
    moveType: MoveType,
    sideEffects: SideEffect[]
  ): boolean {
    const moveMade = this.movementManager.makeMove(
      fromPosition,
      toPosition,
      piece,
      toPiece,
      moveType,
      sideEffects
    );
    if (moveMade) {
      // Notify listeners and send updates if necessary
      this.eventManager.notifyLocalListeners();
      this.eventManager.sendUpdateToRemote();

      this.runPostTurn();
      this.changeTurns();
    }
    return moveMade;
  }

  runPostTurn() {
    // here is where we can run post turn effects eg
    // disabling a poisoned state
    //
  }

  changeTurns() {
    this.gameStateManager.changeTurns();
    this.eventManager.notifyLocalListeners();
    this.eventManager.sendUpdateToRemote();
  }

  // For testing purposes
  modifyGameState() {
    this.gameStateManager.emptyBoard();
    this.placePiece(new Piece(PieceType.Crane, true), { col: 0, row: 7 });
    this.placePiece(new Piece(PieceType.Rhino, true), { col: 2, row: 0 });
    this.placePiece(new Piece(PieceType.Monkey, true), { col: 3, row: 0 });
    this.placePiece(new Piece(PieceType.Fungi, false), { col: 1, row: 4 });
    this.eventManager.notifyLocalListeners();
  }
}
