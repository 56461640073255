import styles from "./Board.module.css";
import globalStyles from "../App.module.css";
import { GameState, IMove, Position } from "../services/zodiacEngine/types";
import { memo, useEffect } from "react";
import Square from "./Square";

interface BoardProps {
  gameState: GameState;
  uiState: {
    potentialMoves: IMove[];
  };
  onDrop: (event: React.DragEvent<HTMLDivElement>, position: Position) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
}

function Board(boardProps: BoardProps) {
  // State to manage highlighted squares during hover
  //const [highlightedSquares, setHighlightedSquares] = useState<Position[]>([]);

  useEffect(() => {
    // This effect will run whenever any prop value changes
    console.log("Props changed:", boardProps);
  }, [boardProps]);

  function isSquarePotentialMove(position: Position): IMove | undefined {
    return boardProps?.uiState?.potentialMoves?.find(
      (x) => x.position.row === position.row && x.position.col === position.col
    );
  }

  function getRowIndex(length: number, index: number): number {
    return length - 1 - index;
  }

  // Function to handle hover events from squares
  // const handleSquareHover = (move?: IMove) => {
  //   if (move && move.sideEffects) {
  //     // Collect positions from sideEffects to highlight
  //     const positions = move.sideEffects.map((se) => se.position);
  //     setHighlightedSquares(positions);
  //   } else {
  //     // Clear highlighted squares when not hovering over a move
  //     setHighlightedSquares([]);
  //   }
  // };

  return (
    <div className={styles.board}>
      {[...boardProps.gameState.board].reverse().map((row, rowIndex) => (
        <div key={rowIndex} className={globalStyles.boardrow}>
          {row.map((square, colIndex) => {
            const position = {
              row: getRowIndex(boardProps.gameState.board.length, rowIndex),
              col: colIndex,
            };

            return (
              <Square
                key={`${position.row}-${position.col}`}
                square={square}
                position={position}
                //onSquareClick={boardProps.onSquareClick}
                onDrop={boardProps.onDrop}
                onDragOver={boardProps.onDragOver}
                move={isSquarePotentialMove(position)}
                //highlightedSquares={highlightedSquares}
                //onHover={handleSquareHover}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}

export default memo(Board);
