import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  AppCheck,
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "firebase/app-check";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBzGiR-QUX5QMN6qs0_hs-bJIHuICY6Iis",
  authDomain: "test1-chat-app.firebaseapp.com",
  projectId: "test1-chat-app",
  storageBucket: "test1-chat-app.appspot.com",
  messagingSenderId: "342011075381",
  appId: "1:342011075381:web:47e55b38f8e68a6acc0a7c",
  measurementId: "G-KFLT2VR536",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const appCheck: AppCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LdJVGIqAAAAAPETX_b0sQJjOQS-Ey2LV-X4qlbg"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

export { db, appCheck, auth };
