import React, { useContext, useState } from "react";
import styles from "./BoardContainer.module.css";
import Board from "./Board";
import {
  Position,
  Mode,
  State,
  PieceType,
} from "../services/zodiacEngine/types";
import { Piece } from "../services/zodiacEngine/Piece";
import { allPieceTypes } from "../services/zodiacEngine/const";
import PieceDrag from "./PieceDrag";
import { GameContext } from "./Providers/GameContext";
import ZodiacEngineService from "./ZodiacEngineService";
import { AuthContext } from "./Providers/AuthContext";

function BoardContainer() {
  const { gameState, potentialMoves, isLightPlayer } = useContext(GameContext)!;
  const { user } = useContext(AuthContext);

  const [lightKing, setLightKingState] = useState(PieceType.Bear);
  const [darkKing, setDarkKingState] = useState(PieceType.Bear);
  const [gameId, setGameId] = useState(
    ZodiacEngineService.zodiacEngine.getGameId()
  );
  const [joinId, setJoinId] = useState("");
  const [copied, setCopied] = useState(false);

  function handleSetupTestBoard() {
    ZodiacEngineService.zodiacEngine.modifyGameState();
  }

  const handleJoinIdChange = (event: any) => {
    setJoinId(event.target.value);
  };

  const handleLightKingChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLightKingState(event.target.value as PieceType);
  };

  const handleDarkKingChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setDarkKingState(event.target.value as PieceType);
  };

  function setLightKing() {
    ZodiacEngineService.zodiacEngine.setLightKing(lightKing);
  }

  function setDarkKing() {
    ZodiacEngineService.zodiacEngine.setDarkKing(darkKing);
  }

  function checkUser() {
    if (!user) {
      throw new Error("User has no id for some strange reason");
    }
  }

  async function liveMode() {
    checkUser();
    var gameId = await ZodiacEngineService.zodiacEngine.switchToLiveMove(
      user!.uid
    );
    setGameId(gameId);
  }

  function joinGame() {
    checkUser();
    ZodiacEngineService.zodiacEngine.joinGame(joinId, user!.uid);
  }

  function devMode() {
    ZodiacEngineService.zodiacEngine.switchToDevMove();
  }

  function handleDrop(
    event: React.DragEvent<HTMLDivElement>,
    position: Position
  ) {
    event.preventDefault();
    const pieceData = event.dataTransfer.getData("piece");

    if (pieceData) {
      const { type, isLight } = JSON.parse(pieceData);
      ZodiacEngineService.zodiacEngine.placePiece(
        new Piece(type as PieceType, isLight),
        position
      );
    }
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  function getPreGameControls(): JSX.Element {
    return (
      <div className={styles.pregame}>
        <select onChange={handleLightKingChange}>
          <option value="">Select light king</option>
          {allPieceTypes.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <button onClick={setLightKing}>Set light king</button>
        <p />
        <select onChange={handleDarkKingChange}>
          <option value="">Select dark king</option>
          {allPieceTypes.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <button onClick={setDarkKing}>Set dark king</button>
        {/* Include the PieceSelector component */}
        <PieceDrag onPiecePlaced={() => {}} />
      </div>
    );
  }

  const copyToClipboard = async () => {
    try {
      if (!gameId) return;
      await navigator.clipboard.writeText(gameId!);
      setCopied(true);
      // Optionally reset 'copied' after a delay so the user can copy again
      setTimeout(() => setCopied(false), 1500);
    } catch (err) {
      alert("Failed to copy!");
      console.error("Failed to copy!", err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <button onClick={handleSetupTestBoard}>Set up test state</button>
        <p />
        <button onClick={joinGame}>Join Game</button>
        <input
          type="text"
          value={joinId} // Bind the input to the state variable
          onChange={handleJoinIdChange} // Update the state on input change
        />
        <p />
        <button onClick={liveMode} disabled={gameState.mode === Mode.Live}>
          Create Game
        </button>
        <button onClick={devMode}>Return to Dev Mode</button>
        <h1>Debug Console</h1>
        UserId: {user?.uid.slice(0, 8) + "..."} <br />
        Mode: {gameState.mode} <br />
        Game Id: {gameId ? gameId!.slice(0, 8) + "..." : "No Game Id"}
        <br />
        <button onClick={copyToClipboard}>
          {copied ? "Copied!" : "Copy Game Id"}
        </button>
        <br />
        State: {gameState.state} <br />
        Whose Turn: {gameState.isLightTurn ? "Light" : "Dark"} <br />
        This player: {isLightPlayer() ? "Light" : "Dark"} <br />
        Turn count: {gameState.turnCount} <br />
        Light King: {gameState.lightKing} <br />
        Dark King: {gameState.darkKing} <br />
        <br />
        {gameState.state === State.PreGame ? getPreGameControls() : null}
      </div>
      <div>
        <Board
          gameState={gameState}
          uiState={{
            potentialMoves: potentialMoves,
          }}
          //onSquareClick={OnSquareClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        />
      </div>
    </div>
  );
}

export default BoardContainer;
